((window) => {
    const carouselTabs = document.querySelectorAll(
        '[data-behaviour="tabbed-tab"]'
    );

    function resetTabs(tabs) {
        tabs.forEach((tab) => {
            tab.classList.remove('active');
        });
    }

    if (carouselTabs.length) {
        carouselTabs.forEach((tab) => {
            tab.addEventListener('click', (e) => {
                e.preventDefault();
                if (e.currentTarget.classList.contains('active')) {
                    return;
                }

                const parent = e.currentTarget.closest(
                    '[data-behaviour="tabbed"]'
                );
                const ref = parent.dataset.carousel;
                const tab = e.currentTarget.dataset.tab;

                window.sliders[ref].pause();
                window.sliders[ref].goTo(tab);
                window.sliders[ref].play();

                const childTabs = parent.querySelector('.tabbed-content-with-categories__tabbed-tabs').querySelectorAll(
                    '[data-behaviour="tabbed-tab"]'
                );
                resetTabs(childTabs);

                e.currentTarget.classList.add('active');
                
                // if there is a parent tab, reset the height of that because this child tab might have a different height than the last
                if (e.currentTarget.dataset.parentTab) {
                    const parentRef = e.currentTarget.dataset.parentTab;
                    setTimeout(() => {window.sliders[parentRef].updateSliderHeight();}, 500);
                }

            });
        });

        window.addEventListener('carousel_moved', (e) => {
            const id = e.detail.id;
            const parent = document.querySelector(
                `[data-behaviour="tabbed"][data-carousel="${id}"]`
            );
            const tabs = parent.querySelectorAll(
                '[data-behaviour="tabbed-tab"]'
            );
            resetTabs(tabs);
            const tab = parent.querySelector(
                `[data-behaviour="tabbed-tab"][data-tab="${e.detail.info
                    .displayIndex - 1}"]`
            );
            tab.classList.add('active');
        });
    }
})(window);
