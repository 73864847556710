import React from 'react';
import { Line } from 'react-chartjs-2';
import HexToRGB from '../../tools/hexToRgba';

const LineChart = ({ data }) => {
  return (
    <>
      {data.map((data, index) => {
        // Make data Labels from strings
        let dataLabels = data.labels;
        dataLabels = dataLabels.split(',');
        dataLabels = [...dataLabels];

        // Loop through data array for each line attributes: (title and colour)
        let dataPoints = data.data.map((item) => {
          // Loop though the each line data
          let dataLine = item.line.map((item) => {
            return Number(item.data_point);
          });

          // return as each data input as an object passed to datasets
          return {
            label: item.data_title,
            fill: false,
            lineTension: 0.1,
            backgroundColor: item.data_colour,
            borderColor: item.data_colour,
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: item.data_colour,
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: HexToRGB(`${item.data_colour}`, 0.8),
            pointHoverBorderColor: HexToRGB(`${item.data_colour}`, 0.8),
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 10,
            data: dataLine,
          };
        });

        data = {
          labels: dataLabels,
          datasets: dataPoints,
          title: data.chart_title,
        };

        return (
          <div key={index} className='charts__line'>
            <h4>{data.title}</h4>
            <Line data={data} />
          </div>
        );
      })}
    </>
  );
};

export default LineChart;
