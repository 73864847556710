import ReactDOM from "react-dom";

const ReactRender = (component, querySelector) => {
  const selector = querySelector;

  if (querySelector) {
    ReactDOM.render(component, selector);
  }
};

export default ReactRender;
