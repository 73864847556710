document.addEventListener('click', (event) => {
    if (!event.target.matches('[data-behaviour="psuedo-select"]')) return;

    const select = event.target;

    select.classList.toggle('active');
});

document.addEventListener('click', (event) => {
    if (!event.target.matches('[data-psuedo-select-option]')) return;

    const select = event.target.closest('[data-behaviour="psuedo-select"]');

    select.classList.remove('active');
});