let sortBy = document.querySelectorAll('[data-taxonomy="news-sort-by"]');


if (sortBy.length > 0) {
  sortBy.forEach((sortBy) => {
    sortBy.addEventListener(
      'change',
      function(event) {
        event.preventDefault
          ? event.preventDefault()
          : (event.returnValue = false);

        const form = this.closest('form');
        let baseUrl = form.getAttribute('action');
        baseUrl += this.value;

        const cat = form.querySelector('[data-hidden="category_name"]');
        if (cat.value) {
          baseUrl += "&category_name=" + cat.value;
        }

        window.location.href = baseUrl;
      },
      { passive: false }
    );
  });
}
