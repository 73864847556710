import React from 'react';
import ReactRender from '../tools/react-render';
import PieChart from './PieChart';
import LineChart from './LineChart';

const pieChart = document.querySelector('[data-behaviour="pie-chart"]')
const lineChart = document.querySelector('[data-behaviour="line-chart"]')


if (pieChart) {
    const attributesData = JSON.parse(
        pieChart.getAttribute('data-attributes')
    );
    ReactRender(
        <PieChart data={attributesData} />,
        pieChart
    );
}

if (lineChart) {
    const attributesData = JSON.parse(
        lineChart.getAttribute('data-attributes')
    );
    ReactRender(
        <LineChart data={attributesData} />,
        lineChart
    );
}
