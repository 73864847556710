import { responsive, createBreakpoint } from "../tools/responsive";

(() => {
    let videos = [...document.querySelectorAll(".hero-banner__video")];

    if (!videos.length) return;

    let sizes = {
        "360": false,
        "540": false,
        "720": false,
        "1080": false,
    };

    // create breakpoints for each video size
    Object.keys(sizes).map((size, i) => createBreakpoint(size, size || null, Object.keys(sizes)[i + 1]));

    // Get all of the videos and sort them into an object by size
    let videosBySize = videos.reduce((videos, video) => {
        Object.keys(sizes).map((size) => {
            if (video.matches(`.hero-banner__video--${size}p`)) {
                videos[size] = video;
            }
        });

        return videos;
    }, sizes);

    // Create a control object that will dictate what video plays at each breakpoint
    let control = Object.keys(videosBySize).reduce((output, size, i) => {
        if (!output[size]) {
            const nextSize = output[Object.keys(output)[i + 1]] || false;
            const prevSize = output[Object.keys(output)[i - 1]] || false;

            output[size] = !prevSize ? nextSize : prevSize;
        }

        return output;
    }, videosBySize);

    // Play the video that matches the current breakpoint from the control
    Object.keys(window.media).map((device, i, devices) =>
        responsive(device, (e) => {
            Object.values(videosBySize).filter(video => video != control[device]).map(async (video) => await video.pause());

            control[device].play();
        })
    );
})();
