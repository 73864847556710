document.addEventListener('click', (e) => {
    if (!e.target.matches('[data-behaviour="mobile-menu"]')) {
        return;
    }
    const toggle = e.target.matches('.is-active') ? 'remove' : 'add';

    e.target.classList[toggle]('is-active');
    document.querySelector('[data-menu="main"]').classList[toggle]('is-active');
    document.querySelector('.site-wrapper').classList[toggle]('menu-open');
    document.querySelector('body').classList[toggle]('lock-scroll');

    // open child menu on target click
    if (e.target.matches('[data-behaviour="child-toggle"]')) {
        e.target.nextElementSibling.classList[toggle]('is-active');
    }
});
