// updated from here: https://codepen.io/callumbw95/pen/mdPXMag

window.media = {};

export const responsiveHandler = (handler) => (e) => e.matches && handler();
export const responsive = (device, handler, init = true) =>
  (init && window.media[device].matches ? (handler() ? true : true) : true) &&
  window.media[device] &&
  media[device].addEventListener('change', responsiveHandler(handler));
export const createBreakpoint = (device, min = null, max = null) =>
  (window.media[device] = window.matchMedia(
    `${min ? `(min-width: ${min}px)` : ""}${min && max ? " and " : ""}${
      max ? `(max-width: ${max}px)` : ""
    }`
  ));

