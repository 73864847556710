import React from 'react';
import {Pie} from 'react-chartjs-2';
import HexToRGB from '../../tools/hexToRgba';

const PieChart = ({ data }) => {
    HexToRGB
    return (
        <>
            {data.map((data, index) => {
                let dataLabels = data.data.map(item => {
                    return item.data_segment
                })
            
                let dataPoints = data.data.map(item => {
                    return item.data_point
                })
            
                let dataColour = data.data.map(item => {
                    return item.data_colour
                })

                let hoverColour = data.data.map(item => {
                    return HexToRGB(`${item.data_colour}`, .8)
                })
                
                data = {
                    labels: dataLabels,
                    datasets: [{
                        data: dataPoints,
                        backgroundColor: dataColour,
                        hoverBackgroundColor: hoverColour,
                    }],
                    title: data.chart_title
                };
                
                return (
                    <div key={index} className="charts__pie">
                        <h4>{data.title}</h4>
                        <Pie  data={data} />
                    </div>
                )
            })}
        </>
    )
}

export default PieChart