import { tns } from 'tiny-slider';
import { generateUniqueId } from '../tools/helper';

((window) => {
    window.sliders = {};
    const initSliders = () => {
        [
            ...document.querySelectorAll('[data-behaviour="tabbed-carousel"]'),
        ].map((el) => {
            const slider = tns({
                container: el,
                items: 1,
                slideBy: 'page',
                autoplay: el.dataset.autoplay ? false : true,
                controls: false,
                nav: false,
                loop: el.dataset.loop ? false : true,
                speed: 0,
                autoHeight: true,
                autoplayTimeout: 10000,
            });

            if (el.dataset.autoplay == 'true') {
                slider.events.on('indexChanged', (info) => {
                    const carousel_moved = new CustomEvent('carousel_moved', {
                        detail: { info: info, id: el.dataset.ref },
                    });
                    window.dispatchEvent(carousel_moved);
                });
            }

            setTimeout(() => {window.sliders[el.dataset.ref].updateSliderHeight();}, 500);

            window.sliders[el.dataset.ref] = slider;
        });
    };

    document.readyState === 'complete'
        ? initSliders()
        : document.addEventListener('DOMContentLoaded', initSliders);
})(window);
