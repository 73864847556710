import { tns } from 'tiny-slider/src/tiny-slider';

const init = () => {
    const sliders = document.querySelectorAll('[data-behaviour="event-carousel"]');

    [...sliders].map(slider => {
        tns({
            container: slider.querySelector('[data-slides]'),
            controls: true,
            controlsContainer: slider.querySelector('[data-controls]'),
            prevButton: slider.querySelector('[data-control="previous"]'),
            nextButton: slider.querySelector('[data-control="next"]'),
            mouseDrag: true,
            speed: 400,
            gutter: 61,
            responsive: {
                0: {
                    items: 1,
                },
                640: {
                    items: 2,
                },
            }
        })
    });
};

document.readyState === 'complete' ? init() : document.addEventListener('DOMContentLoaded', init);